import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import SEO from '../components/seo'

const Workshops = () => {
  return (
    <Layout>
      <SEO title="Workshops" />

      <InfoPagesWrapper>
        <h2>Workshops</h2>
        <div>
          <p>
            I have provided bespoke 1 day workshops for employers covering
            aspects of job application and interview preparation, usually to
            groups of up to 16 people. It’s designed to be interactive insofar
            as I invite questions and participation. Attendees need to feel safe
            and confident in the maintenance of confidentiality.
          </p>
          <p>
            My signature 1 day workshop is titled Prepare for Success - Job
            Application and Interview Preparation.
          </p>
          <p>
            Attendees at this programme have worked in a variety of settings
            within organisations including finance, IT, administration, senior
            managers and frontline clinical staff. Participants receive pre
            workshop material that involves some preparation that serves to
            maximise their opportunity for learning during the workshop.
          </p>
          <p>
            Many job applications involve completion of a comprehensive
            application form which to some seems daunting. At the workshop I
            break down the process into manageable pieces. The programme
            includes a live demonstration of an interview in which I am the
            interviewee. Participants also work in small groups in terms of
            practicing interview technique. I am happy to work with
            organisations in designing a programme that suits your requirements.
          </p>
        </div>
      </InfoPagesWrapper>
    </Layout>
  )
}

export default Workshops
